//
//
//
//
//
//
//
//
//
//
//

import InteractiveMap from '@/components/pages/home/InteractiveMap.vue';
export default {
    name: 'FixedStations',
    components: {
        InteractiveMap
    }
}
